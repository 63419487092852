import React from 'react';
import { graphql } from 'gatsby';
import queryString from 'query-string';
import Notification from '../components/v1/molecules/notification';
import DownloadTenancyGuideForm from '../components/v1/Form/downloadTenancyGuideForm';
import useStoryblok from '../lib/storyblok';
import Bloks from '../components/atoms/Bloks';
import { StoryblokPage } from '../types';
import Layout from '../components/v2/templates/Layout';
import { Section } from '../components/v2/templates/Section';

const TenancyFraudGuide: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  return (
    <Layout location={location} headerData={content.header} footerData={content.footer}>
      <Bloks bloks={content.SEO} />
      <Bloks bloks={content.hero} />

      <Section>
        <Bloks
          bloks={content.formDescription}
          className="col-span-6 xl:col-start-2 xl:col-end-7 mb-4 max-w-full font-serif"
        />
        <div className="col-span-6 xl:col-start-7 xl:col-end-12 w-full bg-pink-light xl:bg-white">
          <DownloadTenancyGuideForm pathname={location.pathname} />
        </div>
      </Section>

      <Bloks bloks={content.sections} />

      <Notification isVisible={queryString.parse(location.search).formSubmit === 'success'} />
    </Layout>
  );
};

export default TenancyFraudGuide;

export const query = graphql`
  query tenancyFraud {
    storyblokEntry(full_slug: { eq: "tenancy-fraud-guide" }) {
      content
    }
  }
`;
