import React, { useState } from 'react';
import { Form, TextField } from '.';
import validateEmail from '../../../utils/validateEmail';
import { Button } from '../atoms/Button';

const DownloadTenancyGuideForm: ObsoleteComponent = ({ version, pathname }) => {
  const [emptyFields, setEmptyFields] = useState(['phone_number', 'full_name', 'company_name']);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const checkLength = (value, fieldName) => {
    if (value.trim().length === 0 && emptyFields && !emptyFields.includes(fieldName)) {
      return setEmptyFields((emptyFields) => [...emptyFields, fieldName]);
    } else {
      const updatedEmptyFields = emptyFields.filter((emptyField) => emptyField !== fieldName);
      return setEmptyFields(updatedEmptyFields);
    }
  };

  const handleFormSubmit = () => {
    if (typeof window !== undefined) {
      window.open(
        'https://f.hubspotusercontent10.net/hubfs/2037069/Homeppl%20Tenancy%20Fraud%20Report%202021.pdf',
      );
    }
  };

  return (
    <Form
      onSubmit={handleFormSubmit}
      action={`${pathname}/?formSubmit=success`}
      formName={`download-tenancy-fraud-guide`}
    >
      <TextField
        label="Full name"
        id="full_name"
        type="text"
        placeholder="John Doe"
        autoComplete="given-name"
        required
        onChange={(e) => checkLength(e.target.value, e.target.name)}
      />
      <TextField
        label="Email address"
        id="email_address"
        type="email"
        placeholder="name@domain.com"
        autoComplete="email"
        required
        onChange={(e) => setIsEmailValid(validateEmail(e.target.value))}
      />
      <TextField
        label="Phone number"
        id="phone_number"
        type="tel"
        placeholder="07400 123456"
        autoComplete="tel"
        required
        onChange={(e) => checkLength(e.target.value, e.target.name)}
      />
      <TextField
        label="CompanyName"
        id="company_name"
        type="text"
        placeholder="Homeppl"
        autoComplete="organization"
        required
        onChange={(e) => checkLength(e.target.value, e.target.name)}
      />
      <Button
        type="submit"
        label="Download guide"
        disabled={emptyFields.length >= 1 || !isEmailValid}
      />
    </Form>
  );
};

export default DownloadTenancyGuideForm;
